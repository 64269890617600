import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ExternalLink from '@common/ExternalLink';

import { Section, Container } from '@components/global';

const epkUrl = 'https://1drv.ms/u/s!AkOQeC19V3QFd8uaTIRSjsvLuxg?e=AnnI1S';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "profile-pic-2021" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="bio">
        <Container>
          <Grid>
            <div>
              <h2>Bio</h2>
              <p>
                Yalung Tang, cantautor venezolano y autor de los sencillos La
                Magia y Encuentro, además de su LP titulado Tus Colores, del
                cual destaco en la opinión de las personas cercanas al proyecto
                el tema Paraguas para ti. Lanzamientos posteriores a su
                participación en el Festival Nuevas Bandas 2014, después de
                haber emprendido otros proyectos musicales a lo largo de su
                vida.
                <br />
                <br />
                Yalung se encuentra establecido actualmente en Montevideo,
                Uruguay. Su EP "El efecto fantasmal", contiene el tema inédito
                Prisión Cuántica e incluye los ya publicados Encuentro y La
                Magia. Mientras prepara el material y afina sus habilidades para
                convertirse en un artista tecnológico.
              </p>
              <p>
                <br />
                <ExternalLink key={epkUrl} href={epkUrl}>
                  Descarga el EPK (Periodistas)
                </ExternalLink>
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
