import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Bio from '@sections/Bio';
// import Brands from '@sections/Brands';
import Releases from '@sections/Releases';
// import Faq from '@sections/Faq';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Releases />
    <Bio />
    <Footer />
  </Layout>
);

export default IndexPage;
