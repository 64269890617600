import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const RELEASES = [
  {
    name: 'El Efecto Fantasmal',
    image: 'efectofantasmal.jpg',
    date: '2022',
    type: 'Album',
    sources: {
      spotify: 'https://open.spotify.com/album/0yOG6jZvIHxGn4tCLU62FX',
      apple:
        'https://music.apple.com/uy/album/el-efecto-fantasmal-single/1608946647',
      deezer: 'https://www.deezer.com/es/album/294498512',
    },
  },
  {
    name: 'La Magia',
    image: 'lamagia.png',
    date: '2021',
    type: 'Single',
    sources: {
      spotify: 'https://open.spotify.com/album/1BkuUmWtnX6PCrhL32JDL4',
      apple: 'https://music.apple.com/uy/album/la-magia-single/1580255046',
      deezer: 'https://www.deezer.com/es/album/250332942',
    },
  },
  {
    name: 'Encuentro',
    image: 'encuentro.jpg',
    date: '2020',
    type: 'Single',
    sources: {
      spotify: 'https://open.spotify.com/track/3DQrtmXV78aZYdyHgt9ZWI',
      apple: 'https://music.apple.com/us/album/encuentro-single/1536727298',
      deezer: 'https://www.deezer.com/en/album/181014252',
    },
  },
  {
    name: 'Tus colores',
    image: 'tuscolores.jpg',
    date: '2019',
    type: 'Album',
    sources: {
      spotify:
        'https://open.spotify.com/album/1MEQihmkPO5j6gzooSni5b?si=LRTj1AUJTBezyF9RE8bfJA',
      apple: 'https://music.apple.com/us/album/tus-colores/1481867655',
      deezer: 'https://www.deezer.com/en/album/113145892',
    },
  },
  {
    name: 'Antártida',
    image: 'antartida.jpg',
    date: '2019',
    type: 'Single',
    sources: {
      spotify:
        'https://open.spotify.com/album/2i3bdYtQkfAbbfqdpL4uCQ?si=yNq0rCf1RmSX_K0Ukstz9A',
      apple:
        'https://music.apple.com/us/album/ant%C3%A1rtida-single/1482838298',
      deezer: 'https://www.deezer.com/en/track/771244942',
    },
  },
];

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "releases" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="releases" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <h1>Releases</h1>
          <ReleaseGrid>
            {RELEASES.map(({ name, image, date, type, sources }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;

              return (
                <div key={name}>
                  <Img fluid={img.childImageSharp.fluid} alt={name} />
                  <Title>{name}</Title>
                  <Subtitle>
                    {date} · {type}
                  </Subtitle>
                  <MusicUrls>
                    <ExternalLink key={sources.spotify} href={sources.spotify}>
                      Spotify
                    </ExternalLink>
                    <ExternalLink key={sources.apple} href={sources.apple}>
                      Apple
                    </ExternalLink>
                    <ExternalLink key={sources.deezer} href={sources.deezer}>
                      Deezer
                    </ExternalLink>
                  </MusicUrls>
                </div>
              );
            })}
          </ReleaseGrid>
        </Container>
      </Section>
    )}
  />
);

const MusicUrls = styled.div`
  display: flex;
  justify-content: space-between;
  color: #000;
  padding: 12px;

  & a {
    text-decoration: none;
    color: ${props => props.theme.color.black.light};
  }
`;

const ReleaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 90%;
  margin-top: 72px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.gray};
`;

export default Team;
